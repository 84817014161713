import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import { Avatar, Button, Divider, Grid, Typography } from "@material-ui/core";

// import { AuthContext } from "../../context/Auth/AuthContext";

import NewTicketModalPageContact from "../../components/NewTicketModalPageContact";

const VcardPreview = ({ contact, numbers }) => {
  const history = useHistory();
  // const { user } = useContext(AuthContext);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [, setContactTicket] = useState({});
  const [selectedContact, setContact] = useState({
    name: "",
    number: 0,
    profilePicUrl: "",
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          let contactObj = {
            name: contact,
            number: numbers.replace(/\D/g, ""),
            email: "",
          };
          const { data } = await api.post("/contact", contactObj);
          setContact(data);
        } catch (err) {
          console.log(err);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [contact, numbers]);

  // const handleNewChat = async () => {
  //     try {
  //         const { data: ticket } = await api.post("/tickets", {
  //             contactId: selectedContact.id,
  //             userId: user.id,
  //             status: "open",
  //         });
  //         history.push(`/tickets/${ticket.id}`);
  //     } catch (err) {
  //         toastError(err);
  //     }
  // }

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.id !== undefined) {
      history.push(`/tickets/${ticket.id}`);
    }
  };
  const formatPhoneNumber = (rawNumber) => {
    return `+${rawNumber.slice(0, 2)} ${rawNumber.slice(2, 4)} ${rawNumber.slice(4, 9)}-${rawNumber.slice(9, 13)}`;
  };
  return (
    <>
      <div
        style={{
          minWidth: "250px",
        }}
      >
        <Grid container spacing={1}>
          <NewTicketModalPageContact
            modalOpen={newTicketModalOpen}
            initialContact={selectedContact}
            onClose={(ticket) => {
              handleCloseOrOpenTicket(ticket);
            }}
          />
          <Grid item xs={2}>
            <div style={{ padding: "10px" }}>
              <Avatar src={selectedContact.profilePicUrl} />
            </div>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" color="primary" gutterBottom style={{ fontWeight: "bold", marginLeft: "16px" }}>
              {selectedContact.name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" style={{ marginLeft: "16px" }}>
              {selectedContact.number}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            {/* {contacts.map((contact) => (
                            <IconButton
                                key={contact.id}
                                size="small"
                                onClick={() => {
                                    setContactTicket(contact);
                                    setNewTicketModalOpen(true);
                                }}
                            >
                                <WhatsApp color="secondary" />
                            </IconButton>
                        ))} */}
            <Button
              fullWidth
              color="primary"
              key={selectedContact.id}
              // onClick={handleNewChat}
              onClick={() => {
                setContactTicket(selectedContact.id);
                setNewTicketModalOpen(true);
              }}
              disabled={!selectedContact.number}
            >
              Conversar
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );

  return (
    <>
      <div
        style={{
          minWidth: "250px",
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <NewTicketModalPageContact
            modalOpen={newTicketModalOpen}
            initialContact={selectedContact}
            onClose={(ticket) => {
              handleCloseOrOpenTicket(ticket);
            }}
          />
          <Grid item xs={2}>
            <div style={{ padding: "10px" }}>
              <Avatar src={selectedContact.profilePicUrl} />
            </div>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1" color="primary" gutterBottom style={{ fontWeight: "bold", marginLeft: "16px" }}>
              {selectedContact.name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" style={{ marginLeft: "16px" }}>
              {selectedContact.number}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Button
              fullWidth
              color="primary"
              key={selectedContact.id}
              onClick={() => {
                setContactTicket(selectedContact.id);
                setNewTicketModalOpen(true);
              }}
              disabled={!selectedContact.number}
            >
              Conversar
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default VcardPreview;
