import React, { useContext, useState, useEffect, useReducer, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ForwardOutlinedIcon from "@material-ui/icons/ForwardOutlined";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import { TextField, Typography } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
// from other file:
import { List, Paper } from "@material-ui/core";

import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
//from selectableticketlistitem

import { useHistory, useParams } from "react-router-dom";

import { parseISO, format, isSameDay } from "date-fns";

import { Checkbox, Avatar, Badge, Divider, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import MarkdownWrapper from "../MarkdownWrapper";
import ContactTag from "../ContactTag";

import clsx from "clsx";

import { system } from "../../config.json";
import useContacts from "../../hooks/useContacts";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 1),
    borderRadius: "10px",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  textInput: {
    width: "98%",
    marginBottom: "10px",
  },
  modalTitle: {
    marginBottom: "0px",
    padding: "none",
  },

  sendButton: {
    float: "right",
    margin: "10px",
  },

  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    maxHeight: "70vh",
    flexDirection: "column",
    overflow: "auto",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    overflowY: "scroll",

    height: "100%",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  ticket: {
    position: "relative",
    width: "400px",
  },
  selectedTicket: {
    backgroundColor: "#eeeeee", // Change this to the desired background color
  },
  pendingTicket: {
    cursor: "unset",
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },
  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  lastMessageTime: {
    justifySelf: "flex-end",
  },
  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },
  contactLastMessage: {
    overflowWrap: "anywhere",
  },
  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },
  bottomButton: {
    position: "relative",
  },
  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    position: "absolute",
    right: 7,
    top: 30,
    height: 20,
  },
  acceptButton: {
    position: "absolute",
    left: "50%",
  },
  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },
  userTag: {
    position: "absolute",
    marginRight: 5,
    right: 10,
    bottom: 30,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },
  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 10,
      margin: 2,
      padding: 3,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },
  secondaryContentSecond: {
    display: "flex",
    marginTop: 2,
    //marginLeft: "5px",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};
//LINK - MsgForwardIconMenu
export default function MsgForwardIconMenu(props) {
  // receive prop messageIds from MEssagesList: message.id
  const { messageIds, ticketId, arrowRotation, customButton, setSelectingMsgsInParent } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { user } = useContext(AuthContext);
  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  const [searchParam, setSearchParam] = useState("");
  const [showClosedTickets, setShowClosedTickets] = useState(true);
  const [showGroups, setShowGroups] = useState(true);

  const [selectedTickets, setSelectedTickets] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleCheckboxChange = (ticketId, isContact = false) => {
    if (isContact) {
      setSelectedContacts((prevSelectedContacts) => {
        if (prevSelectedContacts.includes(ticketId)) {
          /*  console.log("updated selectedContacts: ");
          console.log(prevSelectedContacts.filter((id) => id !== ticketId)); */
          return prevSelectedContacts.filter((id) => id !== ticketId);
        } else {
          /* console.log("updated selectedContacts: ");
          console.log([...prevSelectedContacts, ticketId]); */
          return [...prevSelectedContacts, ticketId];
        }
      });
      return;
    }

    setSelectedTickets((prevSelectedTickets) => {
      if (prevSelectedTickets.includes(ticketId)) {
        return prevSelectedTickets.filter((id) => id !== ticketId);
      } else {
        return [...prevSelectedTickets, ticketId];
      }
    });
  };
  const handleForward = () => {
    api.put("/messages/forward", {
      selectedTickets,
      selectedContacts,
      ticketId,
      messageIds,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectingMsgsInParent(false);
    setSearchParam("");
    setSelectedTickets([]);
    setSelectedContacts([]);
  };

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();
    const delayDebounceFn = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  };

  // ------------------

  // LINK - TicketsListSelectable
  const TicketsListSelectable = (props) => {
    // showGroups can be: showGroups="only" | showGroups="false" or showGroups="true" (shows all tickets)
    const { status, searchParam, showAll, selectedQueueIds, updateCount, style, tags, showGroups } = props;
    const classes = useStyles();
    const [pageNumber, setPageNumber] = useState(1);
    const [contactPageNumber, setContactPageNumber] = useState(1);

    const [ticketsList, dispatch] = useReducer(reducer, []);
    const { user } = useContext(AuthContext);
    const { profile, queues } = user;
    const [settings, setSettings] = useState([]);

    const [alreadyRendered1, setAlreadyRendered1] = useState(false);

    const { tickets, hasMore, loading } = useTickets({
      pageNumber,
      searchParam,
      status,
      showAll,
      tags: JSON.stringify(tags),
      queueIds: JSON.stringify(selectedQueueIds),
    });
    const limit = 50;
    const { contacts, hasMore: hasMoreContacts, loading: loadingContacts, count: contactCount } = useContacts({ searchParam, pageNumber: contactPageNumber, limit });
    /* useEffect(() => {
      // Perform any additional actions you want when contacts change
      loadingContacts
        ? (() => {
            console.log("Loading contacts");
            return;
          })()
        : console.log(`-----------------
      Loaded:
-----------------`);

      console.log(`Contacts have been updated (count: ${contactCount}):`, contacts);
      hasMoreContacts ? console.log("Has more contacts.") : console.log("No more contacts.");
      // For example, you might want to update the list on the screen here
      // (Assuming you have a function to render the list)
      // updateListOnScreen();
    }, [contacts, hasMoreContacts, loadingContacts, contactCount]);
    //console.log("🚀 ~ file: index.js:176 ~ TicketsList ~ tags:", tags); */

    useEffect(() => {
      const fetchSession = async () => {
        try {
          const { data } = await api.get("/settings");
          setSettings(data);
        } catch (err) {
          toastError(err);
        }
      };
      fetchSession();
    }, []);

    useEffect(() => {
      const queueIds = queues.map((q) => q.id);
      const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);

      const getSettingValue = (key) => {
        const { value } = settings.find((s) => s.key === key);
        return value;
      };
      // const allticket = settings && settings.length > 0 && getSettingValue("allTicket") === "enabled";
      const allticket = true;

      if (allticket === true) {
        if (profile === "") {
          dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
        } else {
          dispatch({ type: "LOAD_TICKETS", payload: tickets });
        }
      } else {
        if (profile === "user") {
          dispatch({ type: "LOAD_TICKETS", payload: filteredTickets });
        } else {
          dispatch({ type: "LOAD_TICKETS", payload: tickets });
        }
      }
      // eslint-disable-next-line
    }, [tickets, searchParam]);

    useEffect(() => {
      let currentCount;
      switch (
        showGroups // está contando/filtrando corretamente
      ) {
        case "false":
          currentCount = ticketsList.filter((ticket) => !ticket.isGroup).length;
          break;
        case "only":
          currentCount = ticketsList.filter((ticket) => ticket.isGroup).length;
          break;
        case "true":
          currentCount = ticketsList.length;
          break;
        default:
          currentCount = ticketsList.length;
      }
      if (typeof updateCount === "function") {
        updateCount(currentCount);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketsList, searchParam]);

    const loadMore = () => {
      setPageNumber((prevState) => prevState + 1);
    };
    const loadMoreContacts = () => {
      setContactPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
      if ((!hasMore || loading) && (!hasMoreContacts || loadingContacts)) return;

      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        if (!loading) {
          // Remember the current scroll position before loading more
          const currentScrollPosition = scrollTop;

          loadMore();

          // Teleport the scroll position up by 200 pixels after loading
          e.currentTarget.scrollTop = currentScrollPosition - 200;
        } else if (!loadingContacts) {
          // Remember the current scroll position before loading more contacts
          const currentScrollPosition = scrollTop;

          loadMoreContacts();

          e.currentTarget.scrollTop = currentScrollPosition - 200;
        }
      }
    };

    return (
      <Paper
        className={classes.ticketsListWrapper}
        style={style}
      >
        <Paper
          square
          name="closed"
          elevation={0}
          className={classes.ticketsList}
          onScroll={handleScroll}
        >
          <List style={{ paddingTop: 0 }}>
            {ticketsList.length === 0 && contacts.length === 0 && !loading ? (
              <div className={classes.noTicketsDiv}>
                <span className={classes.noTicketsTitle}>Nada aqui!</span>
                <p className={classes.noTicketsText}>Nenhum ticket ou contato encontrado com esse status ou termo pesquisado</p>
              </div>
            ) : (
              <>
                {ticketsList
                  .filter((ticket) => {
                    //ticketGroupFilteringcondition(ticket)
                    //console.log(`filtering with showGroups = ${showGroups} ticket: ${JSON.stringify(ticket)}`);
                    switch (showGroups) {
                      case "false":
                        //console.log(`returning  ticket.isGroup == false;`);
                        return ticket.isGroup == false;
                        break;
                      case "only":
                        //console.log(`returning  ticket.isGroup == true;`);
                        return ticket.isGroup == true;
                        break;
                      case "true":
                        //console.log(`returning  true`);
                        return true;
                        break;
                      default:
                        //console.log(`default returning  true`);
                        return true;
                    }
                  })
                  .map((ticket) => (
                    <SelectableTicketListItem
                      ticket={ticket}
                      key={ticket.id}
                    />
                  ))}
                {contacts.map((contact) => (
                  <SelectableContactListItem
                    contact={contact}
                    key={contact.id}
                  />
                ))}
                {
                  // TODO add filtro de grupo/tickets fechados
                }
              </>
            )}
            {loading ? <TicketsListSkeleton /> : undefined}
            {loadingContacts ? <TicketsListSkeleton /> : undefined}
          </List>
        </Paper>
      </Paper>
    );
  };
  // ------------------
  //LINK - SelectableContactListItem
  const SelectableContactListItem = ({ contact }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    return (
      <React.Fragment key={contact.id}>
        {
          // LINK - <Checkbox CONTACT/>
        }

        <ListItem
          dense
          button
          className={clsx(classes.ticket, {
            [classes.selectedTicket]: selectedContacts.includes(contact.id),
          })}
          onClick={() => {
            handleCheckboxChange(contact.id, true);
          }}
        >
          <Checkbox
            checked={selectedContacts.includes(contact?.id)}
            onChange={(e) => e.stopPropagation() && handleCheckboxChange(contact?.id, true)}
            inputProps={{ "aria-label": "primary checkbox" }}
            className={`checkbox-${contact?.id}`}
          />
          <ListItemAvatar>
            <Avatar
              style={{
                width: "50px",
                height: "50px",
              }}
              src={contact?.profilePicUrl}
            />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <span>
                <div>
                  {contact.number && (
                    <Typography
                      /*className={classes.Radiusdot}*/
                      component="span"
                      variant="body2"
                      color="textSecondary"
                      style={{
                        position: "absolute",
                        right: 15,
                        top: 6,
                        height: 16,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {contact.number}
                    </Typography>
                  )}
                </div>
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {contact.name}
                </Typography>
                <br></br>
              </span>
            }
            secondary={
              <>
                {""}
                <Badge
                  className={classes.Radiusdot}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  overlap="rectangular"
                  /**
                     * overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                      display: "inline-flex",
                     */
                  style={{
                    backgroundColor: system.color.lightTheme.palette.primary,
                    height: 16,
                    padding: "5px 5px",
                    position: "inherit",
                    borderRadius: "3px",
                    color: "white",
                    marginRight: "5px",
                    marginBottom: "3px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                  badgeContent={"Contato"}
                />
              </>
            }
          ></ListItemText>
        </ListItem>

        <Divider
          variant="inset"
          component="li"
        />
      </React.Fragment>
    );
  };
  //LINK - SelectableTicketListItem
  const SelectableTicketListItem = ({ ticket }) => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { ticketId } = useParams();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);
    const [tag, setTag] = useState([]);
    const [uName, setUserName] = useState(null);

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        const fetchTicket = async () => {
          try {
            const { data } = await api.get("/tickets/" + ticket.id);
            setTag(data?.contact?.tags);
          } catch (err) {}
        };
        fetchTicket();
      }, 500);
      return () => {
        if (delayDebounceFn !== null) {
          clearTimeout(delayDebounceFn);
        }
      };
    }, [ticket.id, user, history]);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    // const userName = selectedUserName => {
    // 	let userName = null;
    // 	user.queues.forEach(userId => {
    // 		if (userId === selectedUserName.userId) {
    // 			userName = userId.name;
    // 		}
    // 	});
    // 	return {
    // 		userName
    // 	};
    // }

    const queueName = (selectedTicket) => {
      let name = null;
      let color = null;
      user.queues.forEach((userQueue) => {
        if (userQueue.id === selectedTicket.queueId) {
          name = userQueue.name;
          color = userQueue.color;
        }
      });
      return {
        name,
        color,
      };
    };

    return (
      <React.Fragment key={ticket.id}>
        {
          // LINK - <Checkbox/>
        }
        <ListItem
          dense
          button
          className={clsx(classes.ticket, {
            [classes.selectedTicket]: selectedTickets.includes(ticket.id),
          })}
          onClick={() => {
            handleCheckboxChange(ticket.id);
          }}
        >
          <Checkbox
            checked={selectedTickets.includes(ticket.id)}
            onChange={(e) => e.stopPropagation() && handleCheckboxChange(ticket.id)}
            inputProps={{ "aria-label": "primary checkbox" }}
            className={`checkbox-${ticket.id}`}
          />

          <Tooltip
            arrow
            placement="right"
            title={ticket.queue?.name || ticket?.name || i18n.t("ticketsList.items.queueless")}
          >
            <span
              style={{ backgroundColor: ticket.queue?.color || queueName(ticket)?.color || "#7C7C7C" }}
              className={classes.ticketQueueColor}
            ></span>
          </Tooltip>
          <ListItemAvatar>
            <Avatar
              style={{
                width: "50px",
                height: "50px",
              }}
              src={ticket?.contact?.profilePicUrl}
            />
          </ListItemAvatar>

          <ListItemText
            disableTypography
            primary={
              <span>
                <div>
                  {ticket.whatsappId && (
                    <Typography
                      /*className={classes.Radiusdot}*/
                      component="span"
                      variant="body2"
                      color="textSecondary"
                      style={{
                        position: "absolute",
                        right: 15,
                        top: 6,
                        height: 16,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {isSameDay(parseISO(ticket.updatedAt), new Date()) ? <>{format(parseISO(ticket.updatedAt), "HH:mm")}</> : <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>}
                    </Typography>
                  )}
                </div>
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {ticket.contact.name}
                </Typography>
                <br></br>
              </span>
            }
            secondary={
              <span>
                <Typography
                  className={classes.contactLastMessage}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessage ? <MarkdownWrapper>{ticket.lastMessage.slice(0, 60) + (ticket.lastMessage.length > 60 ? "..." : "")}</MarkdownWrapper> : <br />}
                </Typography>

                <br></br>
                {ticket.whatsappId && (
                  <Tooltip title={i18n.t("ticketsList.items.connection")}>
                    <Badge
                      className={classes.Radiusdot}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                      }}
                      overlap="rectangular"
                      /**
                     * overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                      display: "inline-flex",
                     */
                      style={{
                        backgroundColor: system.color.lightTheme.palette.primary,
                        height: 16,
                        padding: "5px 5px",
                        position: "inherit",
                        borderRadius: "3px",
                        color: "white",
                        marginRight: "5px",
                        marginBottom: "3px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                      badgeContent={ticket.whatsapp?.name || i18n.t("ticketsList.items.user")}
                    />
                  </Tooltip>
                )}

                {ticket.queueId && (
                  <Tooltip title={i18n.t("ticketsList.items.queue")}>
                    <Badge
                      className={classes.Radiusdot}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                      }}
                      overlap="rectangular"
                      style={{
                        backgroundColor: ticket.queue?.color || "#7C7C7C",
                        height: 16,
                        padding: "5px 5px",
                        position: "inherit",
                        borderRadius: "3px",
                        color: "white",
                        marginRight: "5px",
                        marginBottom: "3px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                      badgeContent={ticket.queue?.name || "Sem setor"}
                    />
                  </Tooltip>
                )}

                {uName && (
                  <Tooltip title={i18n.t("ticketsList.items.user")}>
                    <Badge
                      className={classes.Radiusdot}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                      }}
                      overlap="rectangular"
                      style={{
                        backgroundColor: "black",
                        height: 16,
                        padding: "5px 5px",
                        position: "inherit",
                        borderRadius: "3px",
                        color: "white",
                        marginRight: "5px",
                        marginBottom: "3px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                      badgeContent={uName}
                    />
                  </Tooltip>
                )}

                <br></br>
                <Tooltip title={i18n.t("ticketsList.items.tags")}>
                  <span className={classes.secondaryContentSecond}>
                    {tag?.map((tag) => {
                      return (
                        <ContactTag
                          tag={tag}
                          key={`ticket-contact-tag-${ticket.id}-${tag.id}`}
                        />
                      );
                    })}
                  </span>
                </Tooltip>
              </span>
            }
          />

          {ticket.status === "closed" && (
            <IconButton
              className={classes.bottomButton}
              color="primary"
            ></IconButton>
          )}
          <Badge
            className={classes.newMessagesCount}
            badgeContent={ticket.unreadMessages}
            overlap="rectangular"
            classes={{
              badge: classes.badgeStyle,
            }}
          />
        </ListItem>
        <Divider
          variant="inset"
          component="li"
        />
      </React.Fragment>
    );
  };
  // --------------------

  return (
    <div>
      {customButton ? (
        <div onClick={handleOpen}>{customButton}</div>
      ) : (
        <IconButton
          aria-label="Encaminhar"
          onClick={handleOpen}
          size="small"
        >
          <ForwardOutlinedIcon
            fontSize="medium"
            style={{ transform: `rotate(${arrowRotation ?? 0}deg)` }}
          />
        </IconButton>
      )}
      <Modal
        aria-labelledby="menu-encaminhamento"
        aria-describedby="menu-encaminhar"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.container}>
              <h2
                id="menu-encaminhar"
                className={classes.modalTitle}
              >
                {selectedTickets.length + selectedContacts.length === 0 ? "Encaminhar para..." : `Encaminhar para ${selectedTickets.length + selectedContacts.length} chat${selectedTickets.length + selectedContacts.length > 1 ? "s" : ""}`}
              </h2>
              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                className={classes.sendButton}
                disabled={selectedTickets.length + selectedContacts.length === 0}
                onClick={() => {
                  //encaminhar msgs
                  handleForward();
                  //fechar modal
                  handleClose();
                }}
              >
                Encaminhar
              </Button>
            </div>
            <TextField
              id="standard-basic"
              className={classes.textInput}
              variant="standard"
              label="Pesquisar tickets ou contatos"
              onChange={handleSearch}
              autoFocus={true}
            />
            <TicketsListSelectable
              showGroups="true"
              status="open"
              tags={undefined}
              selectedQueueIds={selectedQueueIds}
              searchParam={searchParam}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
