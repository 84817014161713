import React, { useEffect, useState, useRef } from "react";
import QRCode from "qrcode.react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import { Formik, Form, Field } from "formik";
import CopyToClipboard from "../CopyToClipboard";
import { DialogTitle, Button, DialogActions, CircularProgress, TextField, Switch, FormControlLabel } from "@material-ui/core";
import { Dialog, DialogContent, Paper, Typography, Box, Divider } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
  const [qrCode, setQrCode] = useState("");
  const [pairingCode, setPairingCode] = useState("");
  const [phone, setPhone] = useState("55");
  const [codeInfo, setCodeInfo] = useState();
  const [updatedCodeInfo, setUpdatedCodeInfo] = useState(null);

  function removeNonNumeric(str) {
    return str.replace(/[^0-9]/g, "");
  }

  const formatPhone = (e) => {
    const phone = removeNonNumeric(e.target.value); // Remove non-numeric characters
    let formattedPhone = "";

    switch (true) {
      case phone.length === 0:
        formattedPhone = "";
        break;
      case phone.length <= 2:
        formattedPhone = `+${phone}`;
        break;
      case phone.length <= 4:
        formattedPhone = `+${phone.slice(0, 2)} (${phone.slice(2)}`;
        break;
      case phone.length <= 8:
        formattedPhone = `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4)}`;
        break;
      case phone.length <= 12:
        formattedPhone = `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 8)}-${phone.slice(8)}`;
        break;
      case phone.length === 13:
        formattedPhone = `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 9)}-${phone.slice(9)}`;
        break;
      default:
        formattedPhone = phone;
    }

    e.target.value = formattedPhone.trim();
    setPhone(removeNonNumeric(formattedPhone.trim()));
  };
  const requestPairingCode = async () => {
    if (!phone) return;
    const codeInfo = await api.post(`whatsapp/${whatsAppId}/pcodecreate`, {
      phone,
    });
    if (!codeInfo) return;
    if (!codeInfo.data) return;
    if (!codeInfo.data.code) return;
    const code = codeInfo.data.code;
    setPairingCode(code);
    setCodeInfo(codeInfo.data);
  };
  const getStoredPairingCode = async () => {
    if (!whatsAppId) return;
    const codeInfo = await api.post(`whatsapp/${whatsAppId}/pcode`);
    //LINK criado
    // codeInfo.data.updatedAt = new Date().getTime() - 1000 * 60 * 60 * 24 * 10;
    // console.log(JSON.stringify(codeInfo, null, 2));
    if (!codeInfo) return;
    if (!codeInfo.data) return;
    if (!codeInfo.data.code) return;
    const code = codeInfo.data.code;
    setPairingCode(code);
    setCodeInfo(codeInfo.data);
  };

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
        if (data.number) {
          setPhone(data.number);
        }
        getStoredPairingCode();
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const socket = openSocket();

    socket.on("whatsappSession", (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose]);

  function limitDecimalPlaces(num, decimalPlaces = 2) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(num * multiplier) / multiplier;
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!codeInfo || !codeInfo.updatedAt) return;
      const codeCreated = new Date(codeInfo.updatedAt).getTime();
      const currentTime = new Date().getTime();
      if (currentTime - codeCreated > 60000) {
        setUpdatedCodeInfo({ ...codeInfo });

        return;
      }
      const incremented = new Date(codeCreated + 1000);
      setUpdatedCodeInfo({ ...codeInfo, updatedAt: incremented });
    }, 1000);

    return () => clearInterval(interval);
  }, [codeInfo]);

  //LINK - PairingCodeField
  const PairingCodeField = (pairingCode) => {
    const codeAge = new Date().valueOf() - new Date(updatedCodeInfo?.updatedAt || codeInfo?.updatedAt).valueOf();
    const s = codeAge / 1000; // code Age In Seconds
    //LINK code age
    let codeAgeToShow;
    let codeAgeUnit;
    if (s < 60) {
      codeAgeToShow = Math.round(s);
      codeAgeUnit = `segundo${codeAgeToShow > 1 ? "s" : ""}`;
    }
    if (s >= 60 && s < 3600) {
      codeAgeToShow = Math.round(s / 60);
      codeAgeUnit = `minuto${codeAgeToShow > 1 ? "s" : ""}`;
    }
    if (s >= 3600 && s < 86400) {
      codeAgeToShow = Math.round(s / 3600);
      codeAgeUnit = `hora${codeAgeToShow > 1 ? "s" : ""}`;
    }
    if (s >= 86400) {
      codeAgeToShow = limitDecimalPlaces(s / 86400);
      codeAgeUnit = `dia${codeAgeToShow > 1 ? "s" : ""}`;
    }

    return pairingCode ? (
      <Box
        boxShadow={"1px 2px 9px #000000"}
        borderRadius={"12px"}
        display="flex"
        justifyContent="center"
        alignContent="center"
        margin="8px"
        padding={"4px"}
        paddingLeft={"16px"}
        paddingRight={"16px"}
      >
        <div
          className="codetwolines"
          style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
        >
          <div
            className="codelineone"
            style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Typography
              color="primary"
              style={{ wordBreak: "break-word" }}
            >
              Código gerado:
            </Typography>
            <Box
              display="flex"
              alignItems="left"
              style={{
                backgroundColor: "rgba(255, 255, 0, 0.1)",
                padding: "8px",
                borderRadius: "4px",
                marginLeft: "8px",
              }}
            >
              {/* backgroundColor: "#f5f5f5" */}
              <Typography
                variant="h6"
                style={{
                  fontFamily: "monospace",
                  color: "red",
                  marginRight: "8px",
                }}
              >
                {`${pairingCode.slice(0, 4)}-${pairingCode.slice(4)}`}
              </Typography>
              <CopyToClipboard
                content={pairingCode}
                color="primary"
              />
            </Box>
          </div>
          <p
            color="black"
            style={{ wordBreak: "break-word", fontSize: "12px" }}
          >
            {`Gerado há ${codeAgeToShow} ${codeAgeUnit} `}
          </p>
        </div>
      </Box>
    ) : null;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogContent style={{ background: "#ffffff" }}>
        <Paper
          elevation={0}
          style={{ background: "#ffffff" }}
        >
          <Typography
            color="primary"
            gutterBottom
          >
            {i18n.t("qrCode.message")}:
          </Typography>
          {qrCode ? (
            <QRCode
              value={qrCode}
              size={256}
            />
          ) : (
            <span>Aguardando o QR Code...</span>
          )}
          <br />
          <Divider style={{ marginTop: "8px", marginBottom: "8px" }}></Divider>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              color="primary"
              gutterBottom
              style={{ width: 300, wordBreak: "break-word" }}
              styles={{ wordWrap: "break-word", wordBreak: "break-word" }}
            >
              Ou digite o número de WhatsApp para pedir um código de acesso:
            </Typography>
            <div style={{ display: "flex" }}>
              <TextField
                placeholder="Digite o número aqui"
                variant="outlined"
                defaultValue="+55"
                autoFocus={true}
                type="tel"
                // style={{ WebkitAppearance: "none" }}
                onChange={(e) => {
                  formatPhone(e);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ overflowWrap: "break-word", overflow: "auto", width: "min-content", marginLeft: "8px" }}
                // disabled={qrCode === ""}
                disabled={phone.length < 10}
                onClick={requestPairingCode}
              >
                {pairingCode ? "Gerar novo" : "Gerar"}
              </Button>
            </div>
            {!phone.startsWith("55") && (
              <div style={{ width: "300px", alignItems: "center", borderRadius: "8px", display: "flex", marginTop: "8px", backgroundColor: "rgba(255, 255, 0, 0.1)" }}>
                <ErrorOutlineIcon
                  htmlColor="#606000"
                  fontSize="small"
                  style={{ marginLeft: "8px", marginRight: "8px", alignSelf: "center" }}
                />
                <Typography
                  color="primary"
                  gutterBottom
                  fontSize=""
                  style={{ padding: "8px", wordBreak: "break-word", color: "#606000", wordWrap: "break-word" }}
                >
                  Usar "55" no início do número (DDI: Brasil). <br></br>Ex: 55 17 93232-8888
                </Typography>
              </div>
            )}
            {PairingCodeField(pairingCode)}
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
