import React, { useState, useEffect, useReducer, useRef } from "react";

import PropTypes from "prop-types";
import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "../../services/socket-io";
import clsx from "clsx";
import { blue, red } from "@material-ui/core/colors";
import { Checkbox, Button, CircularProgress, Divider, IconButton, makeStyles, Typography, ClickAwayListener } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { AccessTime, Block, Done, DoneAll, ExpandMore, GetApp } from "@material-ui/icons";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import DescriptionIcon from "@material-ui/icons/Description";
import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import Audio from "../Audio";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import MsgForwardIconMenu from "../MessageForwardMenu/MessageForwardMenu";

const useStyles = makeStyles((theme) => ({
  filePreviewImage: {
    position: "relative",
    overflow: "hidden",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  ticketNumber: {
    color: theme.palette.secondary.main,
    padding: 8,
  },

  messagesList: {
    backgroundImage: theme.backgroundImage,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: blue[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)", // Adjust the shadow as needed
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },
  messageWrapperLeft: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 0,
  },

  messageLeft: {
    marginRight: 0,
    marginTop: 1,
    marginBottom: 0,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageWrapperRight: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  messageWrapperSelected: {
    backgroundColor: "rgba(128, 128, 128, 0.2)" /* Transparent gray color (adjust the alpha value as needed) */,
    transition: "background 0.3s",
    zIndex: "0" /* Put the background behind the content */,
  },

  messageRight: {
    marginLeft: 0,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)", // Adjust the shadow as needed
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
    display: "flex",
    //alignItems: "center",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
    color: red[200],
  },

  deletedMsg: {
    color: red[200],
  },

  ackDoneAllIcon: {
    color: blue[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  messageCenter: {
    marginTop: 5,
    alignItems: "center",
    verticalAlign: "center",
    alignContent: "center",
    backgroundColor: "#E1F5FEEB",
    fontSize: "12px",
    minWidth: 100,
    maxWidth: 270,
    color: "#272727",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    boxShadow: "0 1px 1px #b3b3b3",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  function ToastDisplay(props) {
    return (
      <>
        <h4>Mensagem apagada:</h4>
        <p>{props.body}</p>
      </>
    );
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;

    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageToUpdate.isDeleted === true) {
      toast.info(<ToastDisplay body={messageToUpdate.body}></ToastDisplay>);
    }

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticketId, isGroup, isSelectingMsgs, isParentSelecting, updateParentSelectedMsgs }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [botMsgs, setBotMsgs] = useState([]); // [{ id: 1, body: "\u200e/Hello, I'm a bot!", show: false}]
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);
  // is user selecting messages to forward? default: false
  const [selectingMsgs, setSelectingMsgs] = useState(isParentSelecting);
  const [checkBoxedMsgs, setCheckBoxedMsgs] = useState([]);

  useEffect(() => {
    const newBotMsgs = messagesList.map((msg) => {
      if (msg.body && /\u200e/.test(msg.body[0])) {
        return { ...msg, show: false };
      }
      return msg;
    });
    setBotMsgs(newBotMsgs);
  }, [messagesList]);

  useEffect(() => {
    setSelectingMsgs(isParentSelecting);
    if (isParentSelecting === false) {
      setCheckBoxedMsgs([]);
    }
  }, [isParentSelecting]);
  const handleSelectMessages = () => {
    if (selectingMsgs) {
      setSelectingMsgs(false);
      isSelectingMsgs(false);
      handleCloseMessageOptionsMenu();
      setCheckBoxedMsgs([]);
    } else {
      setSelectingMsgs(true);
      isSelectingMsgs(true);
      handleCloseMessageOptionsMenu();
    }
  };
  //LINK - handleCheckboxChange
  const handleCheckboxChange = (messageId) => {
    const isChecked = checkBoxedMsgs.includes(messageId);
    setCheckBoxedMsgs((prevCheckBoxedMsgs) => {
      if (isChecked) {
        /*         console.log(`new state: ${prevCheckBoxedMsgs.filter((id) => id !== messageId)}`);

 */
        updateParentSelectedMsgs(prevCheckBoxedMsgs.filter((id) => id !== messageId));
        return prevCheckBoxedMsgs.filter((id) => id !== messageId);
      } else {
        /*         console.log(`new state: ${[...prevCheckBoxedMsgs, messageId]}`);
         */
        updateParentSelectedMsgs([...prevCheckBoxedMsgs, messageId]);
        return [...prevCheckBoxedMsgs, messageId];
      }
    });
  };
  const RenderMsgCheckbox = ({ messageId, checked, handleChange }) => {
    return (
      <Checkbox
        checked={checked}
        onChange={() => handleChange(messageId)}
        inputProps={{ "aria-label": "primary checkbox" }}
        className={`checkbox-${messageId}`}
      />
    );
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
    if (selectingMsgs) {
      setSelectingMsgs(false);
    }
    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    // const isMounted = { current: true }; // create a ref object to track if the component is mounted
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            // && isMounted.current
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
      // isMounted.current = false; // set the ref object to false when the component is unmounted
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId]);

  const revealMessage = (messageId) => {
    const i = botMsgs.findIndex((m) => m.id === messageId);
    if (i === -1) return;

    setBotMsgs((prevBotMsgs) => {
      prevBotMsgs[i].show = !prevBotMsgs[i].show;
      return [...prevBotMsgs];
    });
  };
  const returnBotMsg = (message) => {
    return botMsgs.find((m) => {
      if (m && typeof m === "object" && m.hasOwnProperty("id")) {
        return m.id === message.id && m.show === false;
      }
    })
      ? "Mensagem automática"
      : message.body;
  };

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.target);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };
  //LINK - openForwardMenu
  const openForwardMenu = () => {};

  const checkMessageMedia = (message) => {
    if (message.mediaType === "location" && message.body.split("|").length >= 2) {
      let locationParts = message.body.split("|");
      let imageLocation = locationParts[0];
      let linkLocation = locationParts[1];

      let descriptionLocation = null;

      if (locationParts.length > 2) descriptionLocation = message.body.split("|")[2];

      return (
        <LocationPreview
          onClick={() => (selectingMsgs ? handleCheckboxChange(message.id) : () => {})}
          image={imageLocation}
          link={linkLocation}
          description={descriptionLocation}
        />
      );
    } else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      // console.log(`MESSAGE BODY: ${message.body}`);
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      if (obj.length > 0 && contact) {
        return (
          <VcardPreview
            onClick={() => (selectingMsgs ? handleCheckboxChange(message.id) : () => {})}
            contact={contact}
            numbers={obj[0].number}
          />
        );
      } else {
        return (
          <VcardPreview
            onClick={() => (selectingMsgs ? handleCheckboxChange(message.id) : () => {})}
            contact={contact}
            numbers=""
          />
        );
        // handle the case where obj is empty
      }
    } else if (message.mediaType === "image") {
      return (
        <span
          onClick={(e) => {
            if (selectingMsgs) {
              e.stopPropagation();
              e.preventDefault();
              handleCheckboxChange(message.id);
            }
          }}
        >
          <ModalImageCors imageUrl={message.mediaUrl} />
        </span>
      );
    } /*else if (message.mediaType === "multi_vcard") {
      console.log("multi_vcard");
      console.log(message);

      if (message.body !== null && message.body !== "") {
        let newBody = JSON.parse(message.body);
        console.log(newBody);
        return (
          <>
            {newBody.map((v) => (
              <VcardPreview contact={v.name} numbers={v.number} />
            ))}
          </>
        );
      } else return <></>;
    }*/ else if (message.mediaType === "audio") {
      return (
        <Audio
          onClick={() => (selectingMsgs ? handleCheckboxChange(message.id) : () => {})}
          url={message.mediaUrl}
        />
      );
    } else if (message.mediaType === "video") {
      return (
        <video
          onClick={() => (selectingMsgs ? handleCheckboxChange(message.id) : () => {})}
          className={classes.messageMedia}
          src={message.mediaUrl}
          controls
        />
      );
    } else {
      const fileExt = message.mediaUrl.split(".").slice(-1)[0];
      return (
        <div>
          <Button
            onClick={(e) => {
              if (selectingMsgs) {
                e.preventDefault();
                e.stopPropagation();
                handleCheckboxChange(message.id);
              }
            }}
            component="a"
            href={message.mediaUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.filePreviewImage}
            style={{ padding: 0, backgroundColor: "transparent", border: "none", cursor: "pointer" }}
          >
            {message.mediaUrl ? (
              <DescriptionIcon
                color="primary"
                fontSize="large"
              />
            ) : (
              /*<img
                src={thumbnailUrl}
                alt={`Thumbnail`}
                style={{ maxHeight: "80px", width: "auto" }}
              />*/
              <DescriptionIcon
                color="primary"
                fontSize="large"
              />
            )}
          </Button>

          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              href={message.mediaUrl}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Download
            </Button>
          </div>
        </div>
      );
    }
  };
  /* 
filepreviewer lib:
<FilePreviewerThumbnail
                file={{
                  url: message.mediaUrl,
                }}
                style={{ cursor: "pointer" }}
              />

              <Dialog
            open={filePreviewIsOpen}
            onClose={() => {
              setFilePreviewOpen(false);
            }}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle id="simple-dialog-title">Visualização rápida</DialogTitle>
            <Typography>{message.mediaUrl.split("public/")[1]}</Typography>
            <Divider />
            <FilePreviewer
              file={{
                url: message.mediaUrl,
              }}
              hideControls={true}
            />
          </Dialog>

  react-js lib:
  <Document file={message.mediaUrl}>
                <Thumbnail />
              </Document>

            
*/
  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return (
        <AccessTime
          fontSize="small"
          className={classes.ackIcons}
        />
      );
    }
    if (message.ack === 1) {
      return (
        <Done
          fontSize="small"
          className={classes.ackIcons}
        />
      );
    }
    if (message.ack === 2) {
      return (
        <DoneAll
          fontSize="small"
          className={classes.ackIcons}
        />
      );
    }
    if (message.ack === 3 || message.ack === 4) {
      return (
        <DoneAll
          fontSize="small"
          className={classes.ackDoneAllIcon}
        />
      );
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText}>{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}</div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}</div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageTicket = message.ticketId;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <div
            key={`ticket-${message.id}`}
            className={classes.ticketNumber}
          >
            #ticket: {messageTicket}
            <hr />
          </div>
        );
      }
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span
            style={{ marginTop: 16 }}
            key={`divider-${message.id}`}
          ></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && <span className={classes.messageContactName}>{message.quotedMsg?.contact?.name}</span>}
          {message.quotedMsg.mediaType === "audio" && (
            <div className={classes.downloadMedia}>
              <audio controls>
                <source
                  src={message.quotedMsg.mediaUrl}
                  type="audio/ogg"
                ></source>
              </audio>
            </div>
          )}
          {message.quotedMsg.mediaType === "video" && (
            <video
              className={classes.messageMedia}
              src={message.quotedMsg.mediaUrl}
              controls
            />
          )}
          {message.quotedMsg.mediaType === "application" && (
            <div className={classes.downloadMedia}>
              <Button
                startIcon={<GetApp />}
                color="primary"
                variant="outlined"
                target="_blank"
                href={message.quotedMsg.mediaUrl}
              >
                Download
              </Button>
            </div>
          )}

          {message.quotedMsg.mediaType === "image" ? (
            <div
              onClick={(e) => {
                if (selectingMsgs) {
                  e.stopPropagation();
                  e.preventDefault();
                  handleCheckboxChange(message.id);
                }
              }}
            >
              <ModalImageCors imageUrl={message.quotedMsg.mediaUrl} />
            </div>
          ) : (
            message.quotedMsg?.body
          )}
        </div>
      </div>
    );
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (message.mediaType === "call_log") {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}
              <div className={classes.messageCenter}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
                {isGroup && <span className={classes.messageContactName}>{message.contact?.name}</span>}
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                    width="20"
                    height="17"
                  >
                    <path
                      fill="#df3333"
                      d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"
                    ></path>
                  </svg>{" "}
                  <span>Chamada de voz/vídeo perdida às {format(parseISO(message.createdAt), "HH:mm")}</span>
                </div>
              </div>
            </React.Fragment>
          );
        }
        if (!message.fromMe) {
          //LINK - !message.fromMe
          return (
            <div key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}
              <div
                className={clsx(null, {
                  [classes.messageWrapperSelected]: checkBoxedMsgs.includes(message.id),
                })}
              >
                <div
                  className={classes.messageWrapperLeft}
                  onClick={() => (selectingMsgs ? handleCheckboxChange(message.id) : () => {})}
                >
                  {selectingMsgs && ( // LINK - Message selection box
                    <RenderMsgCheckbox
                      messageId={message.id}
                      checked={checkBoxedMsgs.includes(message.id)}
                      handleChange={() => {
                        handleCheckboxChange(message.id);
                      }}
                    />
                  )}
                  <React.Fragment key={message.id}>
                    <div
                      className={classes.messageLeft}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpenMessageOptionsMenu(e, message);
                      }}
                    >
                      <IconButton
                        variant="contained"
                        size="small"
                        id="messageActionsButton"
                        disabled={message.isDeleted}
                        className={classes.messageActionsButton}
                        onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                      >
                        <ExpandMore />
                      </IconButton>
                      {isGroup && <span className={classes.messageContactName}>{message.contact?.name}</span>}
                      {message.isDeleted && (
                        <div>
                          <span className={classes.deletedMsg}>
                            <Block
                              color=""
                              fontSize="small"
                              className={classes.deletedIcon}
                            />
                            Mensagem apagada
                          </span>
                        </div>
                      )}
                      {(message.mediaUrl || message.mediaType === `location` || message.mediaType === "vcard") &&
                        //|| message.mediaType === "multi_vcard"

                        checkMessageMedia(message)}

                      <div className={classes.textContentItem}>
                        <div style={{ flex: 1, maxWidth: "100%" }}>
                          {message.body && (
                            <div>
                              {message.quotedMsg && renderQuotedMessage(message)}

                              <MarkdownWrapper>{message.body}</MarkdownWrapper>
                            </div>
                          )}
                        </div>
                        {/* {message.quotedMsg && renderQuotedMessage(message)}
                      {message.body && <Divider /> && <MarkdownWrapper>{message.body}</MarkdownWrapper>} */}

                        <span className={classes.timestamp}>{format(parseISO(message.createdAt), "HH:mm")}</span>
                      </div>
                    </div>
                  </React.Fragment>

                  <MsgForwardIconMenu
                    key={index}
                    messageIds={[message.id]}
                    ticketId={ticketId}
                    setSelectingMsgsInParent={setSelectingMsgs}
                  />
                </div>
              </div>
            </div>
          );
        } else {
          //LINK - message.fromMe
          return (
            <div key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}
              <div
                className={clsx(null, {
                  [classes.messageWrapperSelected]: checkBoxedMsgs.includes(message.id),
                })}
              >
                <div
                  className={classes.messageWrapperRight}
                  onClick={() => (selectingMsgs ? handleCheckboxChange(message.id) : () => {})}
                >
                  {
                    //LINK forward icon
                  }
                  <MsgForwardIconMenu
                    key={index}
                    messageIds={[message.id]}
                    ticketId={ticketId}
                    style={{ padding: 0, marginRight: 0 }}
                    arrowRotation={180}
                    setSelectingMsgsInParent={setSelectingMsgs}
                  />
                  <div
                    className={classes.messageRight}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleOpenMessageOptionsMenu(e, message);
                    }}
                  >
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                    {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard") &&
                      //|| message.mediaType === "multi_vcard"
                      checkMessageMedia(message)}
                    <div
                      className={clsx(classes.textContentItem, {
                        [classes.textContentItemDeleted]: message.isDeleted,
                      })}
                    >
                      {message.isDeleted && (
                        <Block
                          color="disabled"
                          fontSize="small"
                          className={classes.deletedIcon}
                        />
                      )}

                      <div>
                        {message.quotedMsg && renderQuotedMessage(message)}
                        {message.body && /\u200e/.test(message.body[0]) ? (
                          <Button
                            startIcon={<TouchAppIcon />}
                            size="small"
                            onClick={(e) => {
                              revealMessage(message.id);
                            }}
                          >
                            {returnBotMsg(message)}
                          </Button>
                        ) : (
                          <MarkdownWrapper>{message.body}</MarkdownWrapper>
                        )}
                      </div>
                      <span className={classes.timestamp}>
                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                  </div>
                  {selectingMsgs && ( // LINK - Message selection box
                    <RenderMsgCheckbox
                      messageId={message.id}
                      checked={checkBoxedMsgs.includes(message.id)}
                      handleChange={() => {
                        handleCheckboxChange(message.id);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Diga olá para seu novo contato!</div>;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        handleSelectMessages={handleSelectMessages}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;
