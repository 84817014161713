import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import Button from "@material-ui/core/Button";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import MsgForwardIconMenu from "../MessageForwardMenu/MessageForwardMenu";
import { TagsContainer } from "../TagsContainer";
const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    backgroundColor: theme.palette.background.default,
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },
  actionButtons: {
    marginRight: 6,
    display: "flex",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSelectingMsgs, setSelectingMsgs] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [checkBoxedMsgsClone, setCheckBoxedMsgsClone] = useState([]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);
          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deletado com sucesso.");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const MessageActionButtons = () => {
    return (
      <div className={classes.actionButtons}>
        <Button
          size="small"
          variant="contained"
          onClick={(e) => {
            handleSelectingMsgs(false);
          }}
        >
          Cancelar
        </Button>
        {/*  <Button
          startIcon={<FilterNoneIcon />}
          size="small"
          variant="contained"
          onClick={copySelectedMsgsToClipboard}
        >
          Copiar
        </Button> */}
        <MsgForwardIconMenu
          messageIds={checkBoxedMsgsClone}
          ticketId={ticketId}
          setSelectingMsgsInParent={handleSelectingMsgs}
          customButton={
            <Button
              endIcon={<SendIcon />}
              size="small"
              variant="contained"
              color="primary"
              disabled={checkBoxedMsgsClone.length === 0}
              onClick={(e) => {}}
            >
              Encaminhar
            </Button>
          }
        />
      </div>
    );
  };

  const copySelectedMsgsToClipboard = () => {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText("test clipboard");
    }
  };

  const handleSelectingMsgs = (bool) => {
    setSelectingMsgs(bool);
  };

  const updateSelectedMessages = (newState) => {
    setCheckBoxedMsgsClone(newState);
  };

  return (
    <div
      className={classes.root}
      id="drawer-container"
    >
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          <div className={classes.ticketActionButtons}>
            {isSelectingMsgs ? (
              <MessageActionButtons></MessageActionButtons>
            ) : (
              <TicketActionButtons
                ticket={ticket}
                isSelectingMsgs={handleSelectingMsgs}
              />
            )}
          </div>
        </TicketHeader>
        <TagsContainer
          contact={contact}
          loading={loading}
        />
        <ReplyMessageProvider>
          <MessagesList
            ticketId={ticketId}
            isGroup={ticket.isGroup}
            isSelectingMsgs={handleSelectingMsgs}
            isParentSelecting={isSelectingMsgs}
            updateParentSelectedMsgs={updateSelectedMessages}
          ></MessagesList>
          <MessageInput ticketStatus={ticket.status} />
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />
    </div>
  );
};

export default Ticket;
